import React from 'react';

interface IProps {
    data: { stock?: object },
    dataLoading: boolean,
    stock: string
    logo: string
}

export default function ListItemStock(props: IProps) {

    const stockGreenOrRed = (changePercent: string) => parseFloat(changePercent) < 0 ? "red" : "limegreen"

    const data = props?.data[props?.stock]?.["Global Quote"];

    return (
        <li key={props.stock}>
            <div className="data-text stocks">
                {
                    props.dataLoading || !data
                    ? (<div className="stock loading"> Request <br/> overload! <br/> Refresh in 30s... </div>)
                    : (<div className="stock"> {data ? data?.["01. symbol"] : "Overloaded..."} </div>)
                }

                {
                    props.dataLoading || !data
                    ? (null)
                    : (<div className="stock-price"> ${parseFloat(data?.["05. price"]).toFixed(2)} </div>)
                }

                {
                    props.dataLoading || !data
                    ? (null)
                    : (<div className="stock-percent" style={{color: stockGreenOrRed(data?.["10. change percent"])}}>
                        {data?.["10. change percent"]}
                    </div>)
                }
            </div>
            {
                props.dataLoading || !data
                ? (null)
                : <img className={`stock-img-${props.stock}`} src={props.logo} alt="" />
            }
        </li>
    )
}
