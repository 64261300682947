import React from 'react';
import GoogleMapReact from 'google-map-react';
import mapStyles from "./GoogleMapStyles";
import './RenderGoogleMap.css';

interface IMarkerProps {
    lat: number;
    lng: number;
    msg: string;
}

const Marker = (props: IMarkerProps) => {
    return <div className="marker">

        <p>{props.msg}</p>

    </div>
}

export default function RenderGoogleMap() {

    setTimeout(() => console.log("I know you can see my API key. Its restricted..."), 2000)

    return (
        <div className="google-map">

            <GoogleMapReact
            // I know I shouldn't store API keys on the client or github repo's
            // but the only way to hide it is with a server.
            // Since I don't have a server and this is a frontend only app,
            // I will leave the key here since it's restricted to Google maps only
            // and has a 10 req/day limit.
            bootstrapURLKeys={{ key: "AIzaSyAhHqGB0WL4ISL0XDcoiEwzD4E6PWSeIZg" }}
            defaultCenter={{ lng: 1, lat: 55 }}
            defaultZoom={5}
            options={{ styles: mapStyles }}>

                <Marker
                msg={"I'm here."}
                lat={47.37}
                lng={8.5}
                />

                <Marker
                msg={"I want to be here."}
                lat={60.39}
                lng={5.32}
                />

            </GoogleMapReact>

        </div>
    )
}
