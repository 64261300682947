import React, { useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import anime from 'animejs'; // https://animejs.com/documentation
import CSharpLogo from '../../assets/IMG/language_icons/CSharp.svg';
import TypeScriptLogo from '../../assets/IMG/language_icons/Typescript.svg';
import SquareParticles from '../SquareParticles/SquareParticles';

export default function Home() {

    const firstname = React.createRef<HTMLDivElement>();
    const lastname = React.createRef<HTMLDivElement>();
    const logobox = React.createRef<HTMLDivElement>();
    const logobox2 = React.createRef<HTMLDivElement>();
    const textdev = React.createRef<HTMLDivElement>();
    const textfront = React.createRef<HTMLDivElement>();
    const icon1 = React.createRef<HTMLDivElement>();
    const icon2 = React.createRef<HTMLDivElement>();
    const icon3 = React.createRef<HTMLImageElement>();
    const icon4 = React.createRef<HTMLImageElement>();
    const callToAction = React.createRef<HTMLAnchorElement>();

    useEffect(() => {
        anime({ targets: firstname.current, translateX: "52vw", duration: 2550, delay: 140, easing: "easeInOutBack" });
        anime({ targets: lastname.current, translateX: "52vw", duration: 2550, delay: 0, easing: "easeInOutBack" });
        anime({ targets: logobox.current, opacity: [0, 1], translateY: [0, "0.8vmax"], duration: 1250, delay: 3000, easing: "easeOutSine" });
        anime({ targets: logobox2.current, opacity: [0, 1], translateX: [0, "0.8vmax"], duration: 900, delay: 3400, easing: "easeOutSine" });
        anime({ targets: textdev.current, opacity: [0, 1], translateX: [0, "2.8vmax"], duration: 1250, delay: 4250, easing: "easeOutSine" });
        anime({ targets: textfront.current, opacity: [0, 1], translateX: [0, "-2.8vmax"], duration: 1250, delay: 4250, easing: "easeOutSine" });
        anime({ targets: icon1.current, translateY: "86.3vh", duration: 3000, delay: 0, easing: 'easeInOutBack' });
        anime({ targets: icon2.current, translateY: "86.3vh", duration: 3000, delay: 250, easing: 'easeInOutBack' });
        anime({ targets: icon3.current, translateY: "86.3vh", duration: 3000, delay: 500, easing: 'easeInOutBack' });
        anime({ targets: icon4.current, translateY: "86.3vh", duration: 3000, delay: 750, easing: 'easeInOutBack' });
        anime({ targets: callToAction.current, opacity: [0, 1], duration: 2000, delay: 4000, easing: 'easeOutSine' });
        //eslint-disable-next-line
    }, [])

    return (
        <div className="home">

            <SquareParticles applyClassName={"particles-js"} />

            <div className="background-svg"></div>

            <div ref={logobox} className="logo-box">
                <p ref={textdev}>Developer</p>
            </div>

            <div ref={logobox2} className="logo-box2">
                <p ref={textfront}>Frontend</p>
                <i className="code-icon material-icons">code</i>
            </div>

            <div className="names">
                <div ref={firstname} className="first-name">
                    <p>EDDY</p>
                </div>
                <div ref={lastname} className="last-name">
                    <p>HAMZICH.</p>
                </div>
            </div>

            <Link ref={callToAction} to="/skills" className="call-to-action">
                <p> About me &nbsp;<i className="fas fa-angle-down"></i> </p>
            </Link>

            <div className="icons">
                <i ref={icon1} className="javascript fab fa-js-square"></i>
                <i ref={icon2} className="react fab fa-react"></i>
                <img ref={icon3} src={CSharpLogo} className="csharp" alt=""></img>
                <img ref={icon4} src={TypeScriptLogo} className="typescript" alt=""></img>
            </div>

        </div>
    )
}
