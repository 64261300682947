import React from 'react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';

export default function Navbar() {

    return (
        <div className="navbar">
        
            <NavLink exact to="/" className="navbar-logo">
                <p>Eddy.</p>
            </NavLink>

            <NavLink exact to="/" activeStyle={{color: 'cyan'}}>
                <div className="navbar-icon">    
                    <i className="fas fa-home"></i>
                    <p>HOME</p>
                </div>
            </NavLink>

            <NavLink to="/skills" activeStyle={{color: 'cyan'}}>
                <div className="navbar-icon">
                    <i className="fas fa-user-cog"></i>
                    <p>SKILLS</p>
                </div>
            </NavLink>

            <NavLink to="/projects" activeStyle={{color: 'cyan'}}>
                <div className="navbar-icon">
                    <i className="fas fa-eye"></i>
                    <p>PROJECTS</p>
                </div>
            </NavLink>

            <NavLink to="/contact" activeStyle={{color: 'cyan'}}>
                <div className="navbar-icon">
                    <i className="far fa-envelope"></i>
                    <p>CONTACT</p>
                </div>
            </NavLink>

        </div>
    )
}