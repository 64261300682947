export const

PhoneSVG =
`<svg version="1.1"
id="svg8" inkscape:version="0.92.2 (5c3e80d, 2017-08-06)" sodipodi:docname="IPhone_X_vector.svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 478 718"
style="enable-background:new 0 0 478 718;" xml:space="preserve">

<style type="text/css">
.st0{fill:#7D7E7F;}
.st1{fill:#EBEBEB;}
.st2{clip-path:url(#SVGID_2_);fill:#434343;}
.st3{fill:url(#rect5132_1_);}
.st4{fill:url(#rect5132-5_1_);}
.st5{fill:#1E1E1E;}
.st6{fill:#0F0F0F;}
.st7{fill:url(#rect5756_1_);}
.st8{fill:url(#rect5756-4_1_);}
.st9{fill:url(#rect5872);}
</style>

<g id="layer2" inkscape:groupmode="layer" inkscape:label="iPhone X">
<g id="g5754">
<path id="rect5351-5-5" inkscape:connector-curvature="0" sodipodi:nodetypes="sssssssss" d="M417.3,165.8c0.9,0,1.7,0.8,1.7,1.7
v78.9c0,0.9-0.8,1.7-1.7,1.7h-13.1c-0.9,0-1.7-0.8-1.7-1.7v-78.9c0-0.9,0.8-1.7,1.7-1.7H417.3z"/>
</g>
<g id="g5503" transform="translate(0.01350077)">
<path id="rect5351" d="M60.7,96.7h13.1c0.9,0,1.7,0.8,1.7,1.7v24.5c0,0.9-0.8,1.7-1.7,1.7H60.7c-0.9,0-1.7-0.8-1.7-1.7V98.4
C59,97.4,59.7,96.7,60.7,96.7z"/>
</g>
<g id="g5624">
<path id="rect5351-5" inkscape:connector-curvature="0" sodipodi:nodetypes="sssssssss" d="M60.7,148.8c-0.9,0-1.7,0.8-1.7,1.7
v48.7c0,0.9,0.8,1.7,1.7,1.7h13.1c0.9,0,1.7-0.8,1.7-1.7v-48.7c0-0.9-0.8-1.7-1.7-1.7H60.7z"/>
</g>
<g id="g5624-1" transform="translate(0,17.37252)">
<path id="rect5351-5-6" inkscape:connector-curvature="0" sodipodi:nodetypes="sssssssss" d="M60.7,196.9c-0.9,0-1.7,0.8-1.7,1.7
v48.7c0,0.9,0.8,1.7,1.7,1.7h13.1c0.9,0,1.7-0.8,1.7-1.7v-48.7c0-0.9-0.8-1.7-1.7-1.7H60.7z"/>
</g>
<path id="rect4852" inkscape:connector-curvature="0" d="M113.7,0C84.5,0,61,23.5,61,52.6v612.8c0,29.1,23.5,52.6,52.8,52.6h107
l2.2-3.1h7.8h16.7h7.8l2.2,3.1h107c29.2,0,52.8-23.5,52.8-52.6V52.6C417,23.5,393.5,0,364.3,0H113.7z"/>
<g id="g5128">
<g id="g5116" transform="translate(-244.34124)">
<path id="path5106" inkscape:connector-curvature="0" class="st0" d="M306.5,74h4.2v-5h-4.2V74z"/>
<path id="path5104" inkscape:connector-curvature="0" class="st1" d="M306.5,644.3h4.2V74h-4.2V644.3z"/>
<path id="path5102" inkscape:connector-curvature="0" class="st0" d="M306.5,649.2h4.2v-4.9h-4.2V649.2z"/>
<path id="path5100" inkscape:connector-curvature="0" class="st1" d="M660.2,649.2H656v16.4c0,26.5-21.4,47.8-48,47.8H358.7
c-26.6,0-48-21.3-48-47.8v-16.4h-4.2v14.3c0,29.6,23.9,53.4,53.6,53.4h246.5c29.7,0,53.6-23.8,53.6-53.4L660.2,649.2z"/>
<path id="path5098" inkscape:connector-curvature="0" class="st0" d="M660.2,644.3H656v4.9h4.2V644.3z"/>
<path id="path5096" inkscape:connector-curvature="0" class="st1" d="M660.2,74H656v570.3h4.2V74z"/>
<path id="path5094" inkscape:connector-curvature="0" class="st0" d="M660.2,69H656v5h4.2V69z"/>
<path id="rect4873" inkscape:connector-curvature="0" class="st1" d="M360.1,1.5c-29.7,0-53.6,23.8-53.6,53.4V69h4.2V53.3
c0-26.5,21.4-47.8,48-47.8H608c26.6,0,48,21.3,48,47.8V69h4.2V54.9c0-29.6-23.9-53.4-53.6-53.4H360.1z"/>
</g>
<g>
<g>
<g>
<defs>
<path id="SVGID_1_" d="M115.7,1.3C86,1.3,62.1,25.1,62.1,54.7v608.5c0,29.6,23.9,53.4,53.6,53.4h246.5
c29.7,0,53.6-23.8,53.6-53.4V54.7c0-29.6-23.9-53.4-53.6-53.4L115.7,1.3z M114.3,5.2h249.4c26.6,0,48,21.3,48,47.8v612.3
c0,26.5-21.4,47.8-48,47.8H114.3c-26.6,0-48-21.3-48-47.8V53.1C66.3,26.6,87.7,5.2,114.3,5.2z"/>
</defs>
<clipPath id="SVGID_2_">
<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
</clipPath>
<path id="path4878" inkscape:connector-curvature="0" class="st2" d="M96.9,10.8c-6.6-0.2-19.1,1.8-32,16.3
c-18.8,21-2,8.4-2,8.4s11.5-17.8,27.6-20.1s11.2-3.9,11.2-3.9S99.9,10.9,96.9,10.8z M381.1,10.8c-3,0.1-4.8,0.6-4.8,0.6
s-4.9,1.6,11.2,3.9s27.6,20.1,27.6,20.1s16.8,12.6-2-8.4C400.2,12.6,387.7,10.6,381.1,10.8z M56.6,679.1
c-1.4-0.2-0.5,2.3,8.3,12.1c18.8,21,36.8,15.7,36.8,15.7s4.9-1.6-11.2-3.9c-16.1-2.3-27.6-20.1-27.6-20.1
S58.2,679.3,56.6,679.1z M421.4,679.1c-1.6,0.2-6.4,3.8-6.4,3.8s-11.5,17.8-27.6,20.1s-11.2,3.9-11.2,3.9s18,5.3,36.8-15.7
C421.9,681.4,422.9,678.9,421.4,679.1z"/>
</g>
</g>
</g>
</g>
<g id="g5259" transform="translate(-0.11323174)">



<linearGradient id="rect5132_1_" gradientUnits="userSpaceOnUse" x1="399.9918" y1="836.7" x2="408.9138" y2="836.7" gradientTransform="matrix(1 0 0 -1 -0.6544 1196)">
<stop  offset="0" style="stop-color:#404040"/>
<stop  offset="0.6" style="stop-color:#838383"/>
<stop  offset="1" style="stop-color:#A7A7A7"/>
</linearGradient>
<path id="rect5132" inkscape:connector-curvature="0" class="st3" d="M384.3,14.6c-1.2-0.1-0.6,1.1,2,3.5
c8.7,8,14.1,19.7,14.1,32.6v308.6v308.6c0,12.9-5.4,24.6-14.1,32.6c-2.6,2.4-3.2,3.6-2,3.5c0.7-0.1,2-0.6,3.9-1.6
c12.7-6.8,20.1-21.3,20.1-36.8V359.3V53c0-15.5-7.4-30.1-20.1-36.8C386.3,15.2,385,14.6,384.3,14.6z"/>



<linearGradient id="rect5132-5_1_" gradientUnits="userSpaceOnUse" x1="995.9918" y1="836.7" x2="1004.9138" y2="836.7" gradientTransform="matrix(-1 0 0 -1 1074.8809 1196)">
<stop  offset="0" style="stop-color:#404040"/>
<stop  offset="0.6" style="stop-color:#838383"/>
<stop  offset="1" style="stop-color:#A7A7A7"/>
</linearGradient>
<path id="rect5132-5" inkscape:connector-curvature="0" class="st4" d="M94,14.6c1.2-0.1,0.6,1.1-2,3.5
c-8.7,8-14.1,19.7-14.1,32.6v308.6v308.6c0,12.9,5.4,24.6,14.1,32.6c2.6,2.4,3.2,3.6,2,3.5c-0.7-0.1-2-0.6-3.9-1.6
C77.3,695.6,70,681,70,665.5V359.3V53c0-15.5,7.4-30.1,20.1-36.8C91.9,15.2,93.3,14.6,94,14.6z"/>
</g>
<g id="g5823">
<path id="rect5261" class="st5" d="M220.6,26.6h37.5c1.5,0,2.7,1.2,2.7,2.7l0,0c0,1.5-1.2,2.7-2.7,2.7h-37.5
c-1.5,0-2.7-1.2-2.7-2.7l0,0C217.8,27.8,219.1,26.6,220.6,26.6z"/>

<path id="path5267-7" sodipodi:cx="39.183693" sodipodi:cy="8.013669" sodipodi:end="6.2684653" sodipodi:rx="1.0571522" sodipodi:ry="1.0571522" sodipodi:start="0" sodipodi:type="arc" class="st6" d="
M211.1,29.4c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4C209.3,25.4,211.1,27.2,211.1,29.4h-4H211.1z"/>
</g>



<linearGradient id="rect5756_1_" gradientUnits="userSpaceOnUse" x1="286.475" y1="662.0703" x2="365.0833" y2="662.0703" gradientTransform="matrix(3.7795 0 0 -3.7795 -992.0322 2517.5447)">
<stop  offset="0" style="stop-color:#4F4F4F;stop-opacity:0.1308"/>
<stop  offset="7.000000e-02" style="stop-color:#4F4F4F"/>
<stop  offset="0.937" style="stop-color:#4F4F4F;stop-opacity:0.9533"/>
<stop  offset="1" style="stop-color:#4F4F4F;stop-opacity:0"/>
</linearGradient>
<path id="rect5756" inkscape:connector-curvature="0" class="st7" d="M114.3,9c-8.7,0-16.8,2.5-23.6,6.8v5.7
c6.5-4.3,14.3-6.7,22.7-6.7h99.8h52.1h99.8c8.4,0,16.2,2.5,22.7,6.7v-5.7C381,11.4,372.9,9,364.2,9h-98.9h-52.1H114.3z"/>



<linearGradient id="rect5756-4_1_" gradientUnits="userSpaceOnUse" x1="286.475" y1="281.6649" x2="365.0833" y2="281.6649" gradientTransform="matrix(3.7795 0 0 3.7795 -992.0322 -361.8026)">
<stop  offset="0" style="stop-color:#4F4F4F;stop-opacity:0.1308"/>
<stop  offset="7.000000e-02" style="stop-color:#4F4F4F"/>
<stop  offset="0.937" style="stop-color:#4F4F4F;stop-opacity:0.9533"/>
<stop  offset="1" style="stop-color:#4F4F4F;stop-opacity:0"/>
</linearGradient>
<path id="rect5756-4" inkscape:connector-curvature="0" class="st8" d="M114.3,709c-8.7,0-16.8-2.5-23.6-6.8v-5.7
c6.5,4.3,14.3,6.7,22.7,6.7h99.8h52.1h99.8c8.4,0,16.2-2.5,22.7-6.7v5.7c-6.8,4.3-14.9,6.8-23.6,6.8h-98.9h-52.1H114.3z"/>



<linearGradient id="rect5872" x1="50%" y1="0%" x2="50%" y2="100%">
<stop offset="0%">
<animate attributeName="stop-color" values="#3F5EFB; #FC466B; #3F5EFB" dur="5s" repeatCount="indefinite"></animate>
</stop>
<stop offset="100%">
<animate attributeName="stop-color" values="#FC466B; #3F5EFB; #FC466B" dur="5s" repeatCount="indefinite"></animate>
</stop>
</linearGradient>
<path id="rect5872" class="st9" d="M114.8,21.4c-17.8,0-32.1,14.3-32.1,32v611.3c0,17.7,14.3,32,32.1,32h248.4
c17.8,0,32.1-14.3,32.1-32V53.4c0-17.7-14.3-32-32.1-32h-31.4c-3,0-5.4,2.4-5.4,5.3v2.5c0,9.6-7.8,17.4-17.4,17.4H168.9
c-9.7,0-17.4-7.7-17.4-17.4v-2.5c0-2.9-2.4-5.3-5.4-5.3C146.1,21.4,114.8,21.4,114.8,21.4z"/>



<path id="path5267-7-7" sodipodi:cx="64.264961" sodipodi:cy="7.8693609" sodipodi:end="6.2684653" sodipodi:rx="2.1329119" sodipodi:ry="2.1095259" sodipodi:start="0" sodipodi:type="arc" class="st6" d="
M310,28.9c0,4.4-3.6,7.9-8,7.9s-8.1-3.5-8.1-7.9s3.5-8,8-8c4.4,0,8.1,3.5,8.1,7.8l-8.1,0.1L310,28.9L310,28.9z"/>
</g>
</svg>`