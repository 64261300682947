import React from 'react';
import './Skills.css';
import { NavLink } from 'react-router-dom';

import Transition from '../Transition/Transition';
import SquareParticles from '../SquareParticles/SquareParticles';
import Phone from './subcomponents/Phone/Phone';

export default function Skills() {

    return (
        <div className="skills">

            <SquareParticles applyClassName={"particles-js"} />

            <Transition />

            <Phone />

            <div className="skills-content">
                <h1>Skills & Experience</h1>
                    <br/>
                    <p>
                        The main area of my expertise is web development.
                        <br/>
                        <br/>
                        JavaScript, TypeScript, React, Next.js, C#, ASP.NET Core, building web apps with custom features, animations, and interactive layouts!
                        <br/>
                        <br/>
                        I also know how to navigate cloud platforms like AWS, deploy servers with certificates/https, setup reverse proxies like nginx and more.
                        <br/>
                        <br/>
                        I am 26 years old and have been programming professionally for 5 years.
                        <br/>
                        <br/>
                        My english is C2 level according to <a href="https://cambridgeenglish.org/test-your-english">cambridgeenglish.org</a>, which is the highest level.
                        <br/>
                        <br/>
                        Visit my <a target="_blank" rel="noopener noreferrer" href="https://github.com/EddyHamzich">Github profile</a> for more details or just <NavLink to="/contact">contact me. </NavLink>
                    </p>
            </div>

        </div>
    )
}
