import React from 'react';
import './Transition.css';

// Animations are done with CSS!

export default function Transition() {
    return (
        <div className="transition-animation" data-testid="transition-test">

            <div className="open-top">
                <div className="loader">
                    <div className="loader-bar"></div>
                </div>
                <div className="anim-box1"></div>
            </div>
            
            <div className="open-bottom">
                <div className="anim-box2"></div>
            </div>

        </div>
    )
}
