import React, { useState, useEffect } from 'react';

import anime from 'animejs';
import InlineSVG from 'svg-inline-react';
import { PhoneSVG } from './PhoneSVG';

import LogoTesla from '../../../../assets/IMG/stock_logos/Tesla.svg';
import LogoAmazon from '../../../../assets/IMG/stock_logos/Amazon.svg';
import LogoApple from '../../../../assets/IMG/stock_logos/Apple.svg';
import LogoAMD from '../../../../assets/IMG/stock_logos/AMD.svg';

import IconJapan from '../../../../assets/IMG/country_icons/japan.svg';
import IconAustria from '../../../../assets/IMG/country_icons/austria.svg';
import IconSwitzerland from '../../../../assets/IMG/country_icons/switzerland.svg';
import IconSlovenia from '../../../../assets/IMG/country_icons/slovenia.svg';

import ListItemStock from './ListItemStock';
import ListItemWeather from './ListItemWeather';

const mapLogos: { [key: string]: any } = {
    TSLA: LogoTesla,
    AMZN: LogoAmazon,
    AAPL: LogoApple,
    AMD: LogoAMD,
    W1: IconJapan,
    W2: IconAustria,
    W3: IconSwitzerland,
    W4: IconSlovenia
}

export default function Phone() {

    const [screenSection, setScreenSection] = useState(true) // true === stocks, false === weather
    const [dataLoading, setDataLoading] = useState(true)
    const [data, setData] = useState<object>({})

    let phoneSVG = React.createRef<HTMLDivElement>();

    function phoneSVGanimation() {
        anime({
            targets: phoneSVG.current,
            translateX: "-116vmin",
            opacity: [0, 1],
            duration: 1500,
            delay: 2500,
            easing: "easeOutSine"
        })
    }

    async function getData() {
        const fetchStocks = (symbol: string) => fetch(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=7MOENB7T9XZ2OF4F`);
        const fetchWeather = (city: string) => fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&mode=json&units=metric&APPID=15a066a5e37a3b1fa863209d0ce1126a`);
    
        const symbols = ["TSLA", "AMZN", "AAPL", "AMD"]
        const cities = ["Tokyo", "Vienna", "Zurich", "Ljubljana"]
    
        await Promise.all([
            ...symbols.map(symbol => fetchStocks(symbol).then(async (res) => await res.json())),
            ...cities.map(city => fetchWeather(city).then(async (res) => await res.json()))
        ])
        .then(([TSLA, AMZN, AAPL, AMD, W1, W2, W3, W4]) => {
            if([TSLA, AMZN, AAPL, AMD, W1, W2, W3, W4].some(element => "Note" in element)
            || [TSLA, AMZN, AAPL, AMD, W1, W2, W3, W4].some(obj => Object.keys(obj).length === 0)) {
                console.error("error fetching data...")
            } else {
                setData({ TSLA, AMZN, AAPL, AMD, W1, W2, W3, W4 })
                setDataLoading(false)
            }
        })
    }

    useEffect(() => {
        (async () => {
            phoneSVGanimation()
            await getData()
        })()
        //eslint-disable-next-line
    }, [])

    return (
        <div className="iphone" ref={phoneSVG}>

            <InlineSVG className="iphone-svg" src={PhoneSVG} children={null} />

            <div className="iphone-screen-container">
            
                <div className={screenSection === true ? "iphone-section-stocks" : "iphone-section-weather"}>
                    <div className="container-iphone-buttons"></div>
                </div>
                
                <div className="iphone-buttons">
                    <i onClick={() => setScreenSection(!screenSection)} className="fas fa-arrow-circle-left"></i>
                    <i onClick={() => setScreenSection(!screenSection)} className="fas fa-arrow-circle-right"></i>
                </div>

                <div className="section-content">
                    {screenSection ? 
                        (<ul className="content-stock">
                            <p>FINANCE</p>
                            {["TSLA", "AMZN", "AAPL", "AMD"].map(stock =>
                                <ListItemStock key={stock} dataLoading={dataLoading} data={data} stock={stock} logo={mapLogos[stock]} />
                            )}
                        </ul>)
                        :
                        (<ul className="content-weather">
                            <p>WEATHER</p>
                            {["W1", "W2", "W3", "W4"].map(weather =>
                                <ListItemWeather key={weather} dataLoading={dataLoading} data={data} weather={weather} countryIcon={mapLogos[weather]} />
                            )}
                        </ul>)
                    }
                </div>
            </div>
        </div>
    )
}