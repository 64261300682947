import React from 'react';
import './Projects.css';

import Transition from '../Transition/Transition';
import SquareParticles from '../SquareParticles/SquareParticles';

const Video = require("./timder.mp4");

export default function Projects() {

    return (
        <div className="projects">

            <SquareParticles applyClassName={"particles-js"} />

            <Transition />

            <div className="projects-content">

                <h1>Projects</h1>

                <ul className="projects-list">

                    <li className="project">
                        <div className="description">
                            <a className="title" href="https://timder.netlify.app">https://timder.app</a>
                            <br/>
                            <br/>
                            <p>
                                This is a dating app demo I've built for fun.
                                <br/>
                                <br/>
                                It is open source and the code is available
                                <a
                                href="https://github.com/EddyHamzich/DatingAppFullstack"
                                target="_blank"
                                rel="noopener noreferrer"
                                > here</a>.
                                <br />
                                <br />
                                It has a fully working messaging/matching system and took me a few weeks to build.
                                <br/>
                                <br/>
                                Backend: ASP.NET Core 3.0 | Entity Framework
                                <br/>
                                <br/>
                                Frontend: React 17 | TypeScript | Hooks | Context API
                            </p>
                        </div>
                        <div className="video-container">
                            <video className="video-playing" autoPlay={true} loop={true} src={Video}></video>
                        </div>
                    </li>

                </ul>

            </div>

        </div>
    )
}