import React from 'react';

import IconCloudy from '../../../../assets/IMG/weather_icons/cloudy.svg';
import IconClear from '../../../../assets/IMG/weather_icons/clear.svg';
import IconRainy from '../../../../assets/IMG/weather_icons/rainy.svg';
import IconSnowy from '../../../../assets/IMG/weather_icons/snowy.svg';
import IconThunder from '../../../../assets/IMG/weather_icons/thunder.svg';

interface IProps {
    data: object,
    dataLoading: boolean,
    weather: string,
    countryIcon: string
}

export default function ListItemWeather(props: IProps) {

    function weatherIcon(weatherData: any) {
        switch(props.dataLoading === false && weatherData) {
            case "Clouds": case "Mist": case "Fog":
                return (<img className={"icon-img-weather"} src={IconCloudy} alt=""></img>);
            case "Rain": case "Drizzle":
                return (<img className={"icon-img-weather"} src={IconRainy} alt=""></img>);
            case "Thunderstorm":
                return (<img className={"icon-img-weather"} src={IconThunder} alt=""></img>);
            case "Snow":
                return (<img className={"icon-img-weather"} src={IconSnowy} alt=""></img>);
            case "Clear":
                return (<img className={"icon-img-weather"} src={IconClear} alt=""></img>);
            default:
                return null;
        }
    }

    return (
        <li key={props.weather}>
            <div className="data-text weathers">
                {
                props.dataLoading
                ? (<div className="stock loading"> Request <br/> overload! <br/> Refresh 20s... </div>)
                : (<div className="stock"> {props.data[props.weather]["name"]} <img className="icon-img-country" src={props.countryIcon} alt=""></img> </div>)
                }

                {
                props.dataLoading
                ? (null)
                : (<div className="stock-price"> {props.data[props.weather]["weather"][0]["main"]} </div>)
                }

                {
                props.dataLoading
                ? (null)
                : (<div className="stock-percent" style={{color: "cyan"}}> {props.data[props.weather]["main"]["temp"]} °C </div>)
                }
            </div>

            {
            props.dataLoading
            ? (null)
            : (weatherIcon(props.data[props.weather]["weather"][0]["main"]))
            }

        </li>
    )
}
