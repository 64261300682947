import React from 'react';
import './SquareParticles.css';

import Particles from 'react-particles-js';
import { getGPUTier } from 'detect-gpu';

interface IProps {
    applyClassName: string
}

export default function SquareParticles(props: IProps) {

    const GPUTier = getGPUTier({
        mobileBenchmarkPercentages: [0, 50, 30, 20], // (Default) [TIER_0, TIER_1, TIER_2, TIER_3]
        desktopBenchmarkPercentages: [0, 50, 30, 20], // (Default) [TIER_0, TIER_1, TIER_2, TIER_3]
    });

    return (
        <div className="particles-setup" data-testid="particles-test">
            {
                (GPUTier.tier === "GPU_MOBILE_TIER_0" ) || (GPUTier.tier === "GPU_DESKTOP_TIER_0")
                ? (null)
                : (<Particles
                    className={props.applyClassName}
                    params={{
                        particles: {
                            number: {
                                value: 50,
                                density: {
                                    enable: true,
                                    value_area: 800
                                }
                            },
                            color: {
                                value: "#000000"
                            },
                            shape: {
                                type: "edge",
                                stroke: {
                                    width: 0,
                                    color: "#000000"
                                },
                                polygon: {
                                    nb_sides: 5
                                },
                                image: {
                                    width: 100,
                                    height: 100
                                }
                            },
                            opacity: {
                                value: 0.5,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 1,
                                    opacity_min: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: 20,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 60,
                                    size_min: 0.1,
                                    sync: false
                                }
                            },
                            line_linked: {
                                enable: false,
                                distance: 500,
                                color: "#000000",
                                opacity: 0.4,
                                width: 2
                            },
                            move: {
                                enable: true,
                                speed: 1,
                                direction: "top-right",
                                random: false,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: {
                                    enable: false,
                                    rotateX: 600,
                                    rotateY: 1200
                                }
                            }
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: false,
                                    mode: "bubble"
                                },
                                onclick: {
                                    enable: false,
                                    mode: "repulse"
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 400,
                                    line_linked: {
                                        opacity: 0.5
                                    }
                                },
                                bubble: {
                                    distance: 500,
                                    size: 4,
                                    duration: 0,
                                    opacity: 1,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4
                                },
                                push: {
                                    particles_nb: 4
                                },
                                remove: {
                                    particles_nb: 2
                                }
                            }
                        },
                        retina_detect: true
                    }}
                />)
            }
            <div className="particles-cover"></div>
        </div>
    )
}
