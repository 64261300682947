import React, { useState } from 'react';
import './Contact.css';

import Transition from '../Transition/Transition';
import RenderGoogleMap from './subcomponents/RenderGoogleMap/RenderGoogleMap';

export default function Contact() {

    const [showCheckmark, setShowCheckmark] = useState(false)
    const email = "edi.hamzic5@protonmail.com"
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(email)
        setShowCheckmark(true)
    }

    return (
        <div className="contact">

            <Transition />

            <form className="contact-form">
                <h1>Contact me</h1>
                <p className="contact-text">
                    {email}
                    <i
                    onClick={() => copyToClipboard()}
                    className={showCheckmark ? "copy-icon colored fas fa-check" : "copy-icon far fa-copy"}
                    > </i>
                </p>
                <p className="contact-text">
                    github.com/EddyHamzich
                    <a
                    href="https://github.com/EddyHamzich"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="copy-icon fab fa-github"
                    > </a>
                </p>
                <p className="contact-text">
                    Edi Hamzić
                    <br />
                    Niederweningen
                    <br />
                    8166
                    <br />
                    Zürich 
                </p>
            </form>

            <RenderGoogleMap />

        </div>
    )
}